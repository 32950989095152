

/* @import url('https://fonts.googleapis.com/css2?family=Short+Stack&display=swap'); */
@import url('./styles/handrawn.min.css');
@import url('./styles/doodle.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Mynerve&display=swap'); */

@font-face {
  font-family: 'Short Stack';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./assets/fonts/shortstack.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


@font-face {
  font-family: 'Mynerve';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./assets/fonts/mynerve.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Vast Shadow';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./assets/fonts/vastshadow.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./assets/fonts/spacemono.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-weight: 100 700;
  src: url('./assets/fonts/materialsymbolsoutlined.woff2') format('woff2');
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 1,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}
.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

body {
  padding: 0;
  background: #fdf7f1;
  overflow-x: hidden;
  font-family: "Mynerve", cursive;
}

h2 {
  font-weight: 200;
  font-style: normal;
}

#navbar a.active {
  text-decoration: line-through;
}

.designer-text {
  font-family: "Vast Shadow", serif;
  font-weight: 400;
  font-style: normal;
}

.developer-text {
  font-family: "Space Mono", monospace;
  font-weight: 400;
  font-style: normal;
}

@keyframes auto-strike{
  0%   { width : 0; }
  100% { width: 100%; }
}

.auto-strike {
  position: relative;
}

.auto-strike::after {
  content: ' ';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background: black;
  animation-name: auto-strike;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards; 
}








@keyframes strike-through {
  0% {
    width: 0;
    left: 0;
  }
  100% {
    width: 100%;
    left: 0;
  }
}

.strike {
  position: relative;
}

.strike::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 0;
  height: 2px;
  background: black;
  transition: width 0.3s ease-in-out;
}

.strike:hover::after {
  animation: strike-through 1s ease-in-out forwards;
}



@keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

.slide-in {
  overflow: auto;
  animation: slide-in 0.5s ease-out forwards;
}

.slide-out {
  overflow: hidden;
  animation: slide-out 0.5s ease-out forwards;
}
